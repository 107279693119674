import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Seo from '../../components/seo';
import Layout from '../../components/layout';
import { StaticImage } from 'gatsby-plugin-image';
import TestResult from "../../components/test-result";
import { getBlockedImageURL } from '../../utils/utils';


const NetworkPage = () => {
  const [blockedImgURL, setBlockedImgURL] = useState(null);
  const [allowedImgURL, setAllowedImgURL] = useState(null);
  const [resultBlocked, setResultBlocked] = useState(null);
  const [resultAllowed, setResultAllowed] = useState(null);

  // workaround to skip SSR, otherwise onError events are not triggered
  useEffect(async () => {
    const url = await getBlockedImageURL();
    const parsedURL = new URL(url, document.location.origin);
    setBlockedImgURL(parsedURL.toString());
    parsedURL.searchParams.append('allow', 'true');
    setAllowedImgURL(parsedURL.toString());
  }, []);

  return (
    <Layout>
      <h1>Block Trackers</h1>

      <h2>Test #1: Blocking</h2>
      <p>
        Check that basic blocking functionality is working as expected. We test that a blocking filter describing a
        partial path blocks its target. Rule: <code>-click-tracker.</code>
      </p>
      <div className="flex mb-10">
        <div className="w-1/2">
          <h3>Control Case</h3>
          <div className="border-2 border-gray-300 p-5">
            <StaticImage
              placeholder="none"
              loading="eager"
              src="../../images/join-crumbs.jpg"
              alt="control image"
              height={300}
            />
          </div>
          <p>You should see the above image.</p>
        </div>
        <div className="flex-none w-1" />
        <div className="w-1/2">
          <h3>Test Case</h3>
          <TestResult result={resultBlocked}>
            <div className="border-2 p-5">
              {
                blockedImgURL &&
                <img
                  className="mb-0"
                  src={blockedImgURL}
                  height={300}
                  alt="should be broken"
                  onLoad={() => {
                    setResultBlocked(false)
                  }}
                  onError={() => {
                    setResultBlocked(true)
                  }}
                />
              }
            </div>
          </TestResult>
          <p>You should <b>NOT</b> see the above image.</p>
        </div>
      </div>

      <h2>Test #2: Allow listing</h2>
      <p>
        Checking that allow listing works. Requests from this domain that contain <code>allow=true</code> query
        parameter
        should not be blocked.
      </p>
      <div className="flex mb-10">
        <div className="w-1/2">
          <h3>Control Case</h3>
          <div className="border-2 border-gray-300 p-5">
            <StaticImage
              placeholder="none"
              loading="eager"
              src="../../images/join-crumbs.jpg"
              alt="control image"
              height={300}
            />
          </div>
          <p>You should see the above image.</p>
        </div>
        <div className="flex-none w-1" />
        <div className="w-1/2">
          <h3>Test Case</h3>
          <TestResult result={resultAllowed}>
            <div className="border-2 p-5">
              {
                allowedImgURL &&
                <img
                  className="mb-0"
                  src={allowedImgURL}
                  height={300}
                  alt=""
                  onLoad={() => {
                    setResultAllowed(true)
                  }}
                  onError={() => {
                    setResultAllowed(false)
                  }}
                />
              }
            </div>
          </TestResult>
          <p>You should <b>SEE</b> the above image.</p>
        </div>
      </div>

      <Link to="/">
        Go back
      </Link>
    </Layout>
  )
}

export const Head = () => <Seo title="Block Trackers" />
export default NetworkPage;
